* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: black;
}
body {
  overflow-x: hidden;
}
.operatorSlider .slick-slide {
  padding: 10px;
}
.fixedNav {
  color: #181818 !important;
  text-shadow: none !important;
}
.slick-slide {
  display: flex !important;
  justify-content: center !important;
}
#slick-header .slick-slide {
  display: block !important;
}
.InputGroup {
  width: 49% !important;
}
.speaker-slick .slick-track {
  margin-bottom: 20px;
}
.logo-slider .slick-track {
  display: flex;
  align-items: center;
}

.logo-slider {
  margin: 0 auto;
}
.verticalAlign > input {
  width: 100% !important;
}
.verticalAlign > select {
  width: 100% !important;
}
.phoneInput .flag-dropdown {
  width: 18% !important;
  max-width: 90px !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #9d9d9d !important;
  border-radius: 0px !important;
  display: flex !important;
  justify-content: center !important;
  transition: 0.1s ease all;
}
.phoneInput .flag-dropdown:hover {
  border-bottom: 1px solid #000 !important;
}
.ratingCard .slick-slide {
  padding: 10px !important;
}
.MuiSelect-select {
  padding-bottom: 12px !important;
}
.MuiSelect-select input {
  padding-bottom: 0px !important;
}
.phoneInputContainer {
  margin-top: 4px !important;
}
.flagDropdown {
  margin-top: 11px !important;
  height: 50px !important;
}
.flagDropdown .MuiSelect-select {
  display: flex !important;
  justify-content: center !important;
  padding-right: 0 !important;
  margin: 0 !important;
  margin-right: 3px !important;
  padding-bottom: 12px !important;
}
.flagDropdown > div > div span .custom-arrow {
  margin-left: 11px;
  margin-bottom: 3px;
}
.flagDropdown > div > svg {
  display: none;
}
.event-slider .slick-list {
  padding: 10px 0 !important;
}
/* .popup-content div form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.popup-content div form input {
  margin-bottom: 10px;
} */

.flagInputContainer {
  /* border: 2px solid red; */
}
.flagInputContainer .flagInput {
  margin-top: 0px;
}
.flagInputContainer .flagInput input {
  /* border: 2px solid red; */
  height: 48px;
  width: 100%;
}
.operator_select {
  position: relative;
  cursor: pointer;
}

.operator_select::before {
  content: "";
  position: absolute;
  width: 11px;
  height: 8px;
  top: 20px;
  right: 16px;
  background-image: url(../public//images//icons/drop-down.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.ReactModal__Overlay {
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.scribd_iframe_embed .toolbar_btn .share_btn {
  display: none !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.end-user-modal-container .flag-dropdown .selected-flag {
  width: 50px;
}
.end-user-modal-container .flag-dropdown .selected-flag .arrow {
  left: 22px;
}
.end-user-modal-container .flag-dropdown .selected-flag .flag {
  left: 13px;
}
.end-user-modal-container .react-tel-input .form-control {
  padding-left: 56px;
}
@media screen and (max-width: 768px) {
  .event-slider .slick-list {
    padding: 5px 0 !important;
  }
  .sliderContainer .slick-slide {
    padding: 10px;
  }
  .event-slider .slick-slide > div {
    width: 100%;
  }
}
@media screen and (max-width: 639px) {
  .InputGroup {
    width: 100% !important;
  }
  .delegatePhone .react-tel-input {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .sliderContainer .slick-slide > div {
    width: 100%;
  }
}
